@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Dancing+Script:wght@700&family=Source+Sans+3:wght@400;600;700&display=swap');

html,body, #root, .app,.content {
  height: 100% ; 
  width: 100% ;
  font-family: 'Source Sans Pro' , sans-serif;
}

.app {
  display: flex; 
  position: relative; 
}

::-webkit-scrollbar {
  width: 10px; 
}


/* Track */ 

::-webkit-scrollbar-track { 
  background: #e0e0e0
}

/* Handle */ 

::-webkit-scrollbar-thumb { 
  background: #888
}

/* Handle on Hover*/ 

::-webkit-scrollbar-track:hover { 
  background: #555
}
#img-wrapper {
  display: flex;
  flex-wrap: wrap;
 }

.custom-box {
  position: absolute;
  top: 10px;
  right: 30px;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000; /* Ensure the box is above the map */
}

.logo {
  font-family: 'Dancing Script', cursive;
}

/* .timeline{
  margin-top: '20px';
} */


